import React, { Component } from 'react';
import Menu from './Menu'
import FrameEncuesta from './FrameEncuesta';
import BotonsLogin from './BotonsLogin'
import { Col, Container, Row } from 'react-bootstrap';
import './css/styles.css'
class ScreenWeb
 extends Component {
    constructor(props) {
        super(props);
    }
    state = { 
    
    }

    componentDidMount(){
        
    }
    render() { 
        return (   
            <>
                <Menu isLoggedIn={this.props.user != null && this.props.token != null} user={this.props.user} />
                <Container className='h-50'>
                <div className='contenedorWeb w-100 h-25'>
                    <Row>
                        {/*<Col className='textContainer md-auto'>
                            <div className='text'>
                                <a> 
                                    El invierno de 2021-2022 está siendo uno de los más secos de este siglo. Los meses en los que los embalses suelen recargarse -noviembre, diciembre, enero y febrero- no han tenido casi lluvias: en octubre hubo un 25% menos de lluvias de lo normal; en noviembre; un 29% menos y en diciembre, un 25% menos. En cuanto a enero, ha sido el quinto más seco desde que comenzó la serie en 1961 y el segundo más seco de este siglo después de 2005. Y no parece probable que la situación cambie, según los metereólogos.<br/>
                                    La situación actualmente, según el Ministerio para la Transición Ecológica y el Reto Demográfico, es que la reserva de agua en España está al 44,6 por ciento de su capacidad total y en la última semana se han perdido 88 hectómetros cúbicos (88 mil millones de litros, el 0,2 por ciento de la capacidad total actual de los embalses).
                                    Ante esta situación, que muchos expertos vinculan al cambio climático y sus fenómenos cada vez más extremos, se plantea la disyuntiva sobre cómo actuar. A largo plazo, las soluciones parecen claras: incrementar la eficiencia en el uso del agua, renovar infraestructuras hidráulicas para aumentar la capacidad y reducir pérdidas, construir desaladoras en las costas… Pero, ¿qué hacer en el corto plazo? De persistir esta situación, las restricciones al consumo parecen cada día más inevitables. Es aquí donde surge el debate sobre las restricciones, que afectarán a la población o a ciertos sectores económicos.

                                    El invierno de 2021-2022 está siendo uno de los más secos de este siglo. Los meses en los que los embalses suelen recargarse -noviembre, diciembre, enero y febrero- no han tenido casi lluvias: en octubre hubo un 25% menos de lluvias de lo normal; en noviembre; un 29% menos y en diciembre, un 25% menos. En cuanto a enero, ha sido el quinto más seco desde que comenzó la serie en 1961 y el segundo más seco de este siglo después de 2005. Y no parece probable que la situación cambie, según los metereólogos.<br/>
                                    La situación actualmente, según el Ministerio para la Transición Ecológica y el Reto Demográfico, es que la reserva de agua en España está al 44,6 por ciento de su capacidad total y en la última semana se han perdido 88 hectómetros cúbicos (88 mil millones de litros, el 0,2 por ciento de la capacidad total actual de los embalses).
                                    Ante esta situación, que muchos expertos vinculan al cambio climático y sus fenómenos cada vez más extremos, se plantea la disyuntiva sobre cómo actuar. A largo plazo, las soluciones parecen claras: incrementar la eficiencia en el uso del agua, renovar infraestructuras hidráulicas para aumentar la capacidad y reducir pérdidas, construir desaladoras en las costas… Pero, ¿qué hacer en el corto plazo? De persistir esta situación, las restricciones al consumo parecen cada día más inevitables. Es aquí donde surge el debate sobre las restricciones, que afectarán a la población o a ciertos sectores económicos.
                                
                                </a>
                            </div>
                        </Col>*/}
                        <Col className='md-auto'>
                            {!this.props.isLogin?
                                <BotonsLogin />
                                :
                                <FrameEncuesta user={this.props.user}></FrameEncuesta>
                            }
                        </Col>
                    </Row>
                </div>
            </Container>
            </>
            
         );
    }
}
 
export default ScreenWeb;