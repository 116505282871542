import { Component } from "react";

class BaseSigmaComponent
    extends Component {

    _isMounted = false;


    /**
     * 
     * @param {object} state 
     * @param {() => void=} callback 
     */
    setState(state, callback) {
        if (this._isMounted) {
            super.setState(state, callback);
        }
    }
    /**
     * @param {*} state 
     * @returns {Promise<void>}
     */
    setStatePromise(state) {
        return new Promise((resolve, reject) => {
            if (this._isMounted) {
                this.setState(state, () => { resolve(); });
                return;
            }
            reject();
        });
    }
    componentDidMount() {
        this._isMounted = true;
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
}

export default BaseSigmaComponent;
