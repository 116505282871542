const Config_pre = {
    //HOST_LEAD : "https://pre-panelapi.trustsurvey.es/",    
    HOST_LEAD : "https://pre-flashsurvey-api.trustsurvey.es/",
    HOST_FLASH : "https://pre-flashsurvey-api.trustsurvey.es/",
    //HOST_LEAD : "https://pre-panelapi.sigmados.com:1090/",
    HOST_LEAD_MUNDO : "https://pre-panelapi.trustsurvey.es:1090/elMundoApi/",    
    //HOST_LEAD_MUNDO : "http://localhost:1080/elMundoApi/",
    //HOST_LEAD : "http://localhost:3000",
    MAX_LENGTH_INTRODUCTION: 250
}
const Config = Config_pre;

export default Config