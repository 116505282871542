
import React, { useState, useEffect, useCallback } from 'react';
import './App.css';
import './css/bootstrap.min.css';
import './css/styles.css';
import './App.css';
import { TokenProvider } from './components/TokenContext';
import { SocketService } from './services/socket.io/socket-io.service.js';
import {StorageService} from './services/storage/storage.service.js'
import UserContext, { UserProvider } from './components/UserContext';
import ScreenWeb from './components/Web/ScreenWeb';
import ScreenMobile from './components/Mobile/ScreenMobile'
import { HashRouter as Router, Route, Switch, withRouter, useSearchParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { BrowserView, MobileView} from 'react-device-detect';
import queryString from 'query-string';



function App({history}) {
  const [isReady, setIsReady] = useState();
	const [user, setUser] = useState();
	const [auth, setAuth] = useState();
	const [userAlerts, setUserAlerts] = useState([]);
	const [userFlashSurvey, setUserFlashSurvey] = useState(null);
	const [token, setToken] = useState();
	const [modalDefaultMessage, setModalDefaultMessage] = useState();
	const [modalDefaultContainer, setModalDefaultContainer] = useState();
	const [isLogin, setIsLogin] = useState(false)
	const [origin, setOrigin] = useState("")
	
	useEffect(() => {
		// Triggered when the server emits 'user'.
		SocketService.addListener('user', (userData) => {
			if (userData.isAnonymous) {
				StorageService.removeItem('token');
				setToken();
				setUser();
				setAuth(false);
			} else {
				StorageService.setItem('token', JSON.stringify(userData.token));
				setToken(userData.token.access_token);
				setUser(userData);
				setAuth(true)
				setIsLogin(true)
			}
			if (!isReady) {
				setIsReady(true);
			}
		});
		// Triggered when the server emits 'alerts'.
		SocketService.connect();
	});

  return (
    <UserProvider value ={[user,setUser]}>
      <TokenProvider value={[token, setToken]}>
		  
        <Container className='justify-content-center'>			
				<BrowserView>
					<ScreenWeb user={user} isLogin={isLogin}/>
				</BrowserView>
				<MobileView>
					<ScreenMobile user={user} isLogin={isLogin}/>
				</MobileView>
				

        </Container>
          
      </TokenProvider>
    </UserProvider>
  );
}

export default withRouter(App);
