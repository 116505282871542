import React, { Component } from 'react';
import ImgIconFacebook from '../../assets/img/icons/perfil/facebook.png'
import ImgIconTwitter from '../../assets/img/icons/perfil/twitter.png';
import Config from '../../config/config'
import { Button, Col, Container, Form, Row, Image, Card } from "react-bootstrap";
import { Trans } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import Axios from 'axios';
import axios from 'axios';
import { BrowserView, MobileView} from 'react-device-detect';
const RRSS = [
    { id: `facebookElmundo`, name: 'Facebook', icon: ImgIconFacebook },
    { id: `twitterElmundo`, name: 'Twitter', icon: ImgIconTwitter },
];
class SurveyButtons extends Component {
    constructor(props) {
        super(props);
    }
    state = { 
        index:0,
        flashSurvey:{},
        checked:false,
        answers:[],
        isLoading:false,
        isSend:false,
        logged:false,
        surveyOn:true,
        termsAccepted: false,
     }
     openInNewTab = (ev, url) => {
        ev.preventDefault();
        ev.stopPropagation();

        const win = window.open(url, '_blank');
        win.focus();
    }
     componentDidMount(){
         
         this.initLegal()
     }

     onBtnClickProvider(e) {
        this.setState({ isLoading: true });
       
        //this.props.setLogin()
    }
    onChkbxChange(ev) {
        this.setState({
            termsAccepted: ev.target.checked
        });
    }
    async initLegal() {
        const legal = await this.getLatestLegal();
        
        if (!legal) { return; }
        console.log(legal)
        const frontPrivacy = legal.filter((term) => term.app === 'Front-end');
        const latestPrivacy = frontPrivacy.filter((p) => p.name === 'Privacidad');
        const latestConditions = frontPrivacy.filter((p) => p.name === 'Conditions');

        if (latestPrivacy.length > 0) {
            this.setState({ legalAdvice: latestPrivacy[0] });
        }
        if (latestConditions.length > 0) {
            this.setState({ conditionsAdvice: latestConditions[0] });
        }
    }
    async getLatestLegal() {
        //this.setState({ isLoading: true });

        try {
            const res = await Axios({
                method: 'GET',
                url:Config.HOST_LEAD + 'api/secAndTerms/last',
                headers: {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*"
                },
                withCredentials: true,
                
                    })
            return res.data
        } catch (ex) {
            return false;
        } finally {
            this.setState({ isLoading: false });
        }
    };
    
    Prueba (){
    }
    render() { 
        return ( 
            <Card className=' width-75 m-4 justify-content-lg-center' >
                    <Card.Body className="width-100">
                        <a><b>Encuesta FLASH ¿Debe dimitir Pedro Sánchez? </b> Registrate y opina.</a>
                        <Row className="justify-content-center">
                        <ul className="list-inline d-flex align-items-center mt-4">
                            {RRSS.map((pr, prIndex) => (
                                <li key={prIndex}
                                    className="list-inline-item mx-3">
                                    <Button as="a"
                                        variant="p"
                                        href={`${Config.HOST_LEAD}${pr.id}`}
                                        disabled={!this.state.termsAccepted}
                                        onClick={(e) => { this.onBtnClickProvider(pr.id) }}>
                                        <Image width={"75%"} src={pr.icon}
                                            alt={pr.name} />
                                    </Button>
                                </li>
                                ))}
                            <li>
                                {/*<Button variant="secondary" onClick={()=>this.Prueba()}>
                                    Ping
                                </Button>*/}
                            </li>
                        </ul>
                        </Row> 
                        <Row className="justify-content-center"> 
                                <Form.Group>
                                    <Form.Check type="checkbox">
                                    <Form.Check.Input type="checkbox" checked={this.state.termsAccepted || false} onChange={(e) => this.onChkbxChange(e)} />
                                    <Form.Label className="text-black" >
                                        <div>
                                        Con este click aceptas los
                                        <strong className="cursor-pointer" onClick={(ev) => this.openInNewTab(ev, this.state.conditionsAdvice ? this.state.conditionsAdvice.url : '#')}>
                                            {' '}Términos y Condiciones {' '}
                                        </strong>
                                        y la
                                        <strong className="cursor-pointer" onClick={(ev) => this.openInNewTab(ev, this.state.legalAdvice ? this.state.legalAdvice.url : '#')}>
                                            {' '}Política de Privacidad
                                        </strong>
                                        </div>
                                    </Form.Label>
                                    </Form.Check>
                                </Form.Group>
                        </Row>  
                    </Card.Body>
                </Card>

         );
    }
}
 
export default SurveyButtons;