import React, {  } from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import logoSigmados from '../../css/images/sigmados-by-trust.png';
import { Trans } from 'react-i18next';
import BaseSigmaComponent from '../v2/_base-sigma-component';
import { Dropdown } from 'react-bootstrap';
import { faAward, faCube, faTasks, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


class Menu
	extends BaseSigmaComponent {
	_isMounted = false;

	state = {
		isLoading: true,
		// isLoggedIn: false,
		modals: {
			oauthIsShown: false,
		},
	};

	componentDidMount() {
		super.componentDidMount();
		this._init();
	}
	componentWillUnmount() {
		super.componentWillUnmount();
	}

	async _init() {
		this.setState({ isLoading: true });
		try {
			await Promise.all([
				// this._initIsLoggedIn(),
			]);
		} finally {
			this.setState({ isLoading: false });
		}
	}


	/**
	 * @param {{ label: string, route: string, onClick?: () =>void }} menuItem 
	 */
	_onBtnClickMenuItem(menuItem) {
		if (!menuItem.onClick || typeof (menuItem.onClick) !== 'function') { return; }

		menuItem.onClick();
	}

	render() {
		if (this.state.isLoading || this.props.isLoggedIn == null) { return null; }

		const isOperator = this.props.location.pathname === '/operator';

		return isOperator ? null :
			(
				<React.Fragment>
					<Navbar fixed={'top'} collapseOnSelect expand='lg' className=''  style={{backgroundColor:"#216087", height:'10vh'}}>
						<Container className='d-flex justify-content-center'>
							<Navbar.Brand
								as={NavLink}
								to='/'
								className="w-25">
								
									<a href="https://www.elmundo.es/" data-ue-cmp="MENUHOM01" data-ue-skw="portada" className="col-sm " id="js-logo">
										<svg xmlns="http://www.w3.org/2000/svg" class="ue-c-logo--elmundo" aria-hidden="true" viewBox="0 0 420 56" role="img">
										<path fill="#ffffff" d="M151.851 52.884h17.694v-2.302h-3.797c-.137 0-.476-.068-.948-.204a4.957 4.957 0 0 1-1.561-.609c-.541-.546-.746-1.224-.746-2.512V7.601l15.254 45.283h5.019l15.455-45.351v40.606c0 .611-.271 1.22-.812 1.63-.408.338-1.425.677-2.373.813h-3.934v2.302h25.02v-2.302h-3.732c-2.237 0-3.526-1.288-3.526-3.325V9.77c0-1.492.139-1.965.884-2.44.405-.405 1.423-.746 2.509-.88h3.932V4.007H195.85l-11.863 34.575L172.46 4.007h-20.542v2.442h3.73c2.031 0 3.389 1.287 3.389 3.321v36.133l.068 1.354c0 1.966-1.491 3.325-3.524 3.325h-3.73v2.302zm65.246-46.639h3.865c.136 0 .543.135.947.272.61.135 1.154.339 1.493.609.542.474.744.948.744 1.762v30.979c0 5.561 1.488 8.745 5.019 10.714 3.185 1.965 8.336 3.117 13.286 3.117 5.624 0 11.186-1.627 13.965-3.931 1.425-1.289 2.576-3.12 3.117-5.355.543-1.833.816-4.137.816-6.576V9.634c0-2.033 1.423-3.389 3.593-3.389h3.795V4.007h-17.83v2.238h3.798c1.897 0 3.186 1.423 3.186 3.389v28.542c0 7.728-3.186 10.912-11.184 10.912-5.29 0-9.221-1.831-10.442-5.014-.405-1.021-.542-2.376-.542-5.288V9.634c0-1.965 1.357-3.389 3.459-3.389h3.725V4.007h-24.81v2.238zm51.606 46.639h17.968v-2.302h-3.864c-.068 0-.479-.068-1.016-.137-.614-.202-1.223-.406-1.63-.677-.608-.546-.814-1.087-.814-2.512v-33.35l24.881 38.978h7.525V8.821c0-1.017.812-1.83 2.305-2.237.408-.066.881-.204.95-.204h3.794V4.007h-17.488V6.38h3.727c2.102 0 3.456 1.356 3.456 3.39v30.235L285.448 4.007h-16.676v2.442h3.729c2.034 0 3.458 1.287 3.458 3.321v37.487c0 1.966-1.493 3.325-3.523 3.325h-3.732v2.302h-.001z"></path>
										<path fill="#ffffff" fill-rule="evenodd" d="M320.105 52.884h26.3c13.629 0 23.456-10.508 23.456-25.081 0-8.406-3.864-15.865-10.438-20.134-3.935-2.506-8.203-3.661-13.762-3.661h-25.557V6.38h3.796c2.035 0 3.389 1.289 3.389 3.39v37.487c0 1.288-.27 2.037-.881 2.578-.407.271-1.493.61-2.44.882h-3.864v2.167h.001zm17.693-5.288V9.092c0-2.304.543-2.914 2.645-2.914h6.236c3.457 0 6.779 1.694 8.541 4.405 1.22 1.967 2.102 4.609 2.646 8.476.338 2.71.539 6.711.539 9.625 0 15.118-3.931 22.101-12.472 22.101h-5.422c-2.035-.001-2.713-.746-2.713-3.189zm36.575-19.117c0 14.984 9.154 25.083 22.78 25.083 13.628 0 22.847-10.099 22.847-25.083 0-7.05-2.104-13.219-6.17-17.965-4.067-4.812-9.491-7.186-16.677-7.186-7.187 0-12.406 2.307-16.746 7.186-3.93 4.544-6.034 10.916-6.034 17.965zm12.681-14.167c1.352-5.355 4.811-8.134 10.1-8.134 5.288 0 8.677 2.779 10.169 8.134.946 3.457 1.22 7.118 1.22 14.168 0 6.982-.273 10.577-1.22 14.101-1.425 5.288-4.881 8.137-10.169 8.137-5.289 0-8.678-2.849-10.1-8.137-1.019-3.455-1.357-7.05-1.357-14.101-.001-7.118.338-10.642 1.357-14.168z" clip-rule="evenodd"></path>
										<path fill="#ffffff" d="M0 52.884h43.929l1.355-15.725h-2.577c-1.017 8.744-6.575 13.558-15.794 13.558h-6.779c-1.83 0-2.577-.882-2.577-2.849V27.396h3.527c3.592 0 6.779.813 8.203 2.102 1.421 1.354 2.439 3.863 2.439 6.169 0 .406 0 .883-.067 1.357h2.507V15.667l-2.507-.067v.542c0 5.898-3.73 9.086-10.575 9.086h-3.527V8.549c0-1.828.544-2.371 2.238-2.371h6.778c5.356 0 8.136.813 11.05 3.253 2.168 1.763 3.864 4.407 4.27 6.779.135.61.205 1.422.272 2.442h2.848L43.86 4.007H0V6.38h3.796c1.967 0 3.322 1.221 3.322 3.321v37.624c0 1.969-1.355 3.257-3.389 3.257H0v2.302zM46.002 6.38h3.73c2.1 0 3.456 1.289 3.456 3.39v37.419c0 1.355-.272 2.033-.815 2.579-.475.338-1.491.677-2.438.813h-3.933v2.302h41.962l1.017-19.252h-2.779c-1.627 10.849-6.847 17.085-14.575 17.085h-5.423c-1.833 0-2.646-.882-2.646-2.916V9.77c0-2.034 1.358-3.39 3.392-3.39h3.797V4.007H46.002V6.38z"></path>
										<path fill="#ffffff" d="M146.37 40.062a27.755 27.755 0 0 0 2.762-10.896h-26.233v11.112h23.36c-.001 0-.011.028.111-.216zm-26.413-25.643H96.793a27.775 27.775 0 0 0-3.405 11.814h26.568V14.419zm2.941 0v11.814h26.216a27.878 27.878 0 0 0-3.399-11.814h-22.817zm-2.941 14.747H93.372a27.835 27.835 0 0 0 2.867 11.112h23.719V29.166zm0 14.045H97.924c4.771 7.242 12.821 12.134 22.033 12.556V43.211zm2.941-31.727h20.946C139.067 4.92 131.506.518 122.898.016v11.468zm0 31.727v12.535c9.068-.529 16.97-5.383 21.679-12.535h-21.679zm-2.941-31.727V0c-8.753.398-16.454 4.833-21.293 11.484h21.293z"></path>
										</svg>
									</a>								
									
							</Navbar.Brand>
							
							
						</Container>
					</Navbar>
				</React.Fragment>
			);
	}


	/**
	 * @param {string} modalName
	 * @param {boolean} isShown
	 */
}

export default withRouter(Menu);
