import io from 'socket.io-client';
import Config from '../../config/config.js'

const ioc = io(Config.HOST_LEAD, {
    withCredentials: true,
    autoConnect: true,
});

export class SocketService {
    /**
     * @param {string} listenerName 
     * @param {(emittedData: any) => void} callback 
     */
    static addListener(listenerName, callback) {
        ioc.on(listenerName, (data) => {
            callback(data);
        });
    }
    /**
     * @param {string} listenerName 
     * @param {() => void=} callback 
     */
    static removeListener(listenerName, callback) {
        ioc.off(listenerName, () => {
            callback();
        });
    }

    /**
     * @param {() => void=} callback
     */
    static connect(callback) {
        if (callback) {
            ioc.once('connect', () => {
                callback();
            });
        }
        ioc.open();
    }
    /**
     * 
     * @param {()=> void=} callback 
     */
    static emit(name, data){
        ioc.emit(name,data)
    }
    /**
     * @param {() => void=} callback
     */
    static disconnect(callback) {
        if (callback) {
            ioc.once('disconnect', () => {
                callback();
            });
        }
        ioc.close();
    }

    static reconnect() {
        ioc.close().open();
    }
}