import React from 'react'

const UserContext = React.createContext({
    isAnonymous: true,
    credits: 0,
    profile: {},
})

export const UserProvider = UserContext.Provider
export const UserConsumer = UserContext.Consumer
export default UserContext