
/**
 * A wrap-up service class with static methods
 * that will be used to gather the data stored
 * for the application. If accessing localStorage
 * or cookies is disallowed, an in-memory hash table
 * will be used as a fallback. The info stored
 * at the hash table will be lost on page reload
 */
 export class StorageService {
    /** @type {{[key: string]: string}} */
    static __tbl = {};

    /**
     * @param {string} key 
     * @returns {string}
     */
    static getItem(key) {
        try {
            return localStorage.getItem(key);
        } catch {
            if (!StorageService.__tbl) {
                StorageService.__tbl = {};
            }
            return StorageService.__tbl[key];
        }
    }
    /**
     * @param {string} key The name of the key
     * @param {string} value The value to be stored
     */
    static setItem(key, value) {
        try {
            localStorage.setItem(key, value);
        } catch {
            if (!StorageService.__tbl) {
                StorageService.__tbl = {};
            }
            return StorageService.__tbl[key];
        }
    }

    /**
     * @param {string} key 
     */
    static removeItem(key) {
        try {
            localStorage.removeItem(key);
        } catch {
            if (!StorageService.__tbl) {
                StorageService.__tbl = {};
            }
            delete StorageService.__tbl[key];
        }
    }
}