import React, { Component } from 'react';
import ImgIconFacebook from '../../assets/img/icons/perfil/facebook.png'
import ImgIconTwitter from '../../assets/img/icons/perfil/twitter.png';
import Config from '../../config/config.js'
import { Button, Col, Container, Form, Row, Image, Card } from "react-bootstrap";
import { Trans } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import Axios from 'axios';
import moment from 'moment'

const flashSurvey = [{"questionId":60,"wording":"Si hay sequía, ¿cómo ahorrar agua? (elegir una)","options":[{"optionId":718,"title":"Con restricciones al uso doméstico.","value":"1","position":null,"typeVis":{"optionTypeVisId":4,"name":"RADIO","description":"Respuesta única"}},{"optionId":719,"title":"Elevando el precio del agua.","value":"2","position":null,"typeVis":{"optionTypeVisId":4,"name":"RADIO","description":"Respuesta única"}},{"optionId":720,"title":"Limitando el uso recreativo (piscinas, campos de golf, etc).","value":"3","position":null,"typeVis":{"optionTypeVisId":4,"name":"RADIO","description":"Respuesta única"}},{"optionId":721,"title":"Limitar el uso industrial.","value":"4","position":null,"typeVis":{"optionTypeVisId":4,"name":"RADIO","description":"Respuesta única"}}],"answers":null},]

class FrameEncuesta extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            index:0,
            flashSurvey:{},
            checked:false,
            answers:[],
            isLoading:false,
            isSend:false,
            user:{
                birth_date: props.user.birth_date,
                gender: props.user.gender,
                population:""
            },
        };
        //this.onHandleChange = this.onHandleChange.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }
    checkUserDataProfile(){
        return(
            Object.entries(this.state.user).map(([key,values])=> {
                if(!values){
                    return this.switchInput(key)
                }
                
            })
        )
    }
    getDateMinus18(){
        var date = new Date()
        var less18 = moment(date).subtract(18, 'years').format('YYYY-MM-DD')
        return less18
    }
   /* onHandleChange= async(option) =>{
        const id = option.target.id;
        const answer= option.target.name;  
        const object ={"answer":answer, "option":{"optionId":id}}
        const array = await this.state.answers.concat(object)
        this.setState({
            answers: array
        })
        if(this.state.index < (flashSurvey.length-1) ){
            console.log(this.state.answers)
            this.setState(prevState =>({
                index : this.state.index+1,
            }))
            this.setState({
                checked:false
            })
        }else{
            console.log(this.state.answers)
            
        }
    }*/
    switchInput = key => {
        switch (key){
            case "birth_date":
                return(
                    <Form.Group className="m-4 w-30" controlId={key} key={key}>
                        <Form.Label><strong>Fecha de nacimiento</strong></Form.Label>
                        <Form.Control type="date" max={this.getDateMinus18()} value={this.state.user.birth_date} onChange={this.handleChange}/>
                        
                    </Form.Group>
                )
                break;
            case "gender":
                return(
                    <Form.Group className="m-4 w-30 " controlId={key}>
                        <Form.Label><strong>Género</strong></Form.Label>
                        <Form.Control as="select" onChange={this.handleChange} value={this.state.user.gender}  className="" id="">
                            <option value="0">Selecciona...</option>
                            <option value="1">Hombre</option>
                            <option value="2">Mujer</option>
                        </Form.Control>
                    </Form.Group>
                )
                break;    
            case "population":
                return(
                    <Form.Group className="m-4 w-30 " controlId={key}>
                        <Form.Label><strong>Código Postal</strong></Form.Label>
                        <Form.Control as="input" onChange={this.handleChange}   className="" id="">
                        </Form.Control>
                    </Form.Group>
                )
                break;                  
                
        }
    }
    handleChange = event => {
        this.setState({
            [event.target.id] : event.target.value
        });
        console.log(event.target.id+": "+ event.target.value)

        
    }
    render() { 
        let encuesta = flashSurvey
        let index = this.state.index
        return ( 
            <Card className='AuthCard justify-content-center'>
                <Card.Header>
                <h4 className="card-header-title text-capitalize display-5 font-weight-normal mb-0">Encuesta flash</h4>
            </Card.Header>
            <Card.Body className="">
                <p><b>
                {encuesta[index].wording}
                </b></p>
                <ul className="list-unstyled">
                    <Form>
                        
                    {encuesta[index].options.map((option) => {                                        
                        let content = (
                            <Form.Check
                                key={option.title}
                                label= {option.title}
                                name={option.title}
                                type="radio"
                                checked={this.state.checked ? 'checked': null}
                                answer={option.title}
                                className="mb-3"
                                id={option.optionId}
                                //onClick ={this.onHandleChange}
                            />
                        )
                        return content
                    })}
                    {this.checkUserDataProfile()}
                    <li>
                        <Button variant="success">Enviar</Button>
                    </li>
                    </Form>
                </ul>
          </Card.Body>
            </Card>
         );
    }
}
 
export default FrameEncuesta;